$background-color: #f4f5f6;
$text-color: #484848;
$ligher-text-color: #777777;
$border-color: #d3d3d3;
$primary-color: #d1161f;
$content-background-color: #ffffff;



@import 'utility';


html {
    position: relative;
}


body {
    font-family: "Roboto", "PingFang HK", "PingFang TC", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Heiti TC", "Microsoft JhengHei", sans-serif;
    color: $text-color;
    background-color: $background-color;
    @include mobile-only {
        background-color: $content-background-color;
    }

    -webkit-font-smoothing: antialiased;
}


html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}


.order-id-form {
    padding: 36px;
    padding-top: 48px;

    .textfield {
        height: 48px;
        margin-bottom: 48px;
    }

    .intl-tel-input {
        display: block;
        height: 48px;
        margin-bottom: 48px;
    }

    .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=tel] {
        padding-left: 100px;
    }
}


.order {
    padding: 24px;
}

.orderitem {
    margin-bottom: 16px;

    td {
        text-align: left;
        height: 76px;
        vertical-align: middle;
    }

    &:last-child {
        margin-bottom: 0px;
    }
}

.orderitem__thumbnail {
    width: 76px;

    > div {
        vertical-align: middle;
        display: inline-block;
        width: 76px;
        height: 76px;
        background-color: #aaa;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &:not(:first-child) {
        padding-left: 8px;
    }
}

.orderitem__description {
    padding-left: 16px;
}


.ship-country {
    margin: 32px 0 32px;
}

.ship-country__title {
    text-align: left;
    vertical-align: bottom;
}

.ship-country__value {
    text-align: right;
    vertical-align: bottom;
}

.shipping-profile {
    margin: 32px 0 24px;

    .textfield {
        height: 48px;
        margin-bottom: 8px;
        border-bottom-color: #e3e3e3;
        padding: 0 8px;
        font-size: 16px;
    }

    select.form-select {
        width: 100%;
        height: 48px;
        font-size: 16px;

        &:focus {
            border-color: #AAA;
            box-shadow: 0 0 0 0.2rem rgba(87,100,198,.15);
        }
    }
}

.shipping-profile__form {
    padding: 16px 0px 40px;
    font-size: 16px;
}


.shipping-profile__form__country {
    margin-top: 26px;
    padding-left: 8px;
}


.thankyou {
    padding: 36px;
    text-align: center;
}

.thankyou__image {
    width: 100%;
    margin: 56px auto;
}



@media screen and (min-width: 769px) {
    body {
        text-align: center;
    }

    .order-id-form, .order, .thankyou {
        display: inline-block;
        text-align: left;
        background-color: $content-background-color;
        box-shadow: 0 12px 20px 0 rgba(211, 211, 211, 0.25);
        margin: 100px auto;
        padding: 64px 56px;
    }

    .order-id-form {
        width: 420px;
    }

    .order {
        width: 800px;

        .detail {
            float: left;
            width: 290px;
            margin: 0;
        }

        .shipping-profile {
            float: right;
            width: 350px;
            margin: 0;
        }

        &:after {
            content: "";
            clear: both;
            display: table;
        }
    }

    .thankyou {
        width: 700px;
    }

    .thankyou__image {
        margin-top: 0;
    }
}
