[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

hr {
  border: 0;
  margin: 0;
  border-bottom: 1px solid #d3d3d3; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0; }

body {
  font-size: 15px;
  line-height: 19px;
  font-weight: 400; }

h6 {
  font-size: 13px;
  line-height: 17px;
  font-weight: 300;
  color: #777777; }

h5 {
  font-size: 15px;
  line-height: 19px;
  font-weight: 500; }

h4 {
  font-size: 17px;
  line-height: 22px;
  font-weight: 400; }

h3 {
  font-size: 19px;
  line-height: 24px;
  font-weight: 400; }

h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400; }

.primary-text {
  color: #484848; }

.secondary-text {
  color: #787878; }

.padding-8 {
  padding: 8px; }

.padding-12 {
  padding: 12px; }

.padding-16 {
  padding: 16px; }

.padding-20 {
  padding: 20px; }

.valign-wrapper {
  display: table; }

.valign {
  display: table-cell;
  vertical-align: middle; }

.clearfix:after {
  content: "";
  clear: both;
  display: table; }

table {
  border-collapse: collapse;
  border: none;
  width: 100%;
  height: auto; }
  table tr, table th, table td {
    padding: 0; }

button {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  box-shadow: 0;
  background: none;
  width: 100%;
  height: 60px;
  transition: all 0.3s; }

.textfield {
  appearance: none;
  display: block;
  border: 0;
  border-bottom: .1rem solid #777777;
  outline: 0;
  position: relative;
  background-color: transparent;
  font-size: 16px;
  min-width: 100px;
  width: 100%;
  border-radius: 0;
  color: #484848; }
  .textfield::-webkit-input-placeholder {
    color: #999999; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(72, 72, 72, 0.35); }

.btn-main {
  background-color: #d1161f;
  color: #ffffff;
  outline: 0;
  border: 0; }
  .btn-main[disabled] {
    opacity: .3; }

.loading {
  position: relative; }
  .loading::after {
    opacity: 1;
    animation: loading 450ms infinite linear;
    border: 0.25rem solid #fff;
    border-radius: 99999px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 3.2rem;
    left: 50%;
    margin-left: -1.6rem;
    margin-top: -1.6rem;
    position: absolute;
    top: 50%;
    width: 3.2rem;
    z-index: 0; }

@keyframes loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.iti-flag {
  background-image: url("../images/flags.png"); }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("../images/flags@2x.png"); } }

html {
  position: relative; }

body {
  font-family: "Roboto", "PingFang HK", "PingFang TC", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Heiti TC", "Microsoft JhengHei", sans-serif;
  color: #484848;
  background-color: #f4f5f6;
  -webkit-font-smoothing: antialiased; }
  @media screen and (max-width: 768px) {
    body {
      background-color: #ffffff; } }

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch; }

.order-id-form {
  padding: 36px;
  padding-top: 48px; }
  .order-id-form .textfield {
    height: 48px;
    margin-bottom: 48px; }
  .order-id-form .intl-tel-input {
    display: block;
    height: 48px;
    margin-bottom: 48px; }
  .order-id-form .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=tel] {
    padding-left: 100px; }

.order {
  padding: 24px; }

.orderitem {
  margin-bottom: 16px; }
  .orderitem td {
    text-align: left;
    height: 76px;
    vertical-align: middle; }
  .orderitem:last-child {
    margin-bottom: 0px; }

.orderitem__thumbnail {
  width: 76px; }
  .orderitem__thumbnail > div {
    vertical-align: middle;
    display: inline-block;
    width: 76px;
    height: 76px;
    background-color: #aaa;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  .orderitem__thumbnail:not(:first-child) {
    padding-left: 8px; }

.orderitem__description {
  padding-left: 16px; }

.ship-country {
  margin: 32px 0 32px; }

.ship-country__title {
  text-align: left;
  vertical-align: bottom; }

.ship-country__value {
  text-align: right;
  vertical-align: bottom; }

.shipping-profile {
  margin: 32px 0 24px; }
  .shipping-profile .textfield {
    height: 48px;
    margin-bottom: 8px;
    border-bottom-color: #e3e3e3;
    padding: 0 8px;
    font-size: 16px; }
  .shipping-profile select.form-select {
    width: 100%;
    height: 48px;
    font-size: 16px; }
    .shipping-profile select.form-select:focus {
      border-color: #AAA;
      box-shadow: 0 0 0 0.2rem rgba(87, 100, 198, 0.15); }

.shipping-profile__form {
  padding: 16px 0px 40px;
  font-size: 16px; }

.shipping-profile__form__country {
  margin-top: 26px;
  padding-left: 8px; }

.thankyou {
  padding: 36px;
  text-align: center; }

.thankyou__image {
  width: 100%;
  margin: 56px auto; }

@media screen and (min-width: 769px) {
  body {
    text-align: center; }
  .order-id-form, .order, .thankyou {
    display: inline-block;
    text-align: left;
    background-color: #ffffff;
    box-shadow: 0 12px 20px 0 rgba(211, 211, 211, 0.25);
    margin: 100px auto;
    padding: 64px 56px; }
  .order-id-form {
    width: 420px; }
  .order {
    width: 800px; }
    .order .detail {
      float: left;
      width: 290px;
      margin: 0; }
    .order .shipping-profile {
      float: right;
      width: 350px;
      margin: 0; }
    .order:after {
      content: "";
      clear: both;
      display: table; }
  .thankyou {
    width: 700px; }
  .thankyou__image {
    margin-top: 0; } }
