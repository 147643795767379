@mixin mobile-only {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin desktop-only {
    @media screen and (min-width: 769px) {
        @content;
    }
}

@mixin inner-offset($offset) {
    position: absolute;
    top: $offset;
    bottom: $offset;
    left: $offset;
    right: $offset;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}


hr {
    border: 0;
    margin: 0;
    border-bottom: 1px solid $border-color;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

body {
    font-size: 15px;
    line-height: 19px;
    font-weight: 400;
}

h6 {
    font-size: 13px;
    line-height: 17px;
    font-weight: 300;
    color: $ligher-text-color;
}

h5 {
    font-size: 15px;
    line-height: 19px;
    font-weight: 500;
}

h4 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 400;
}

h3 {
    font-size: 19px;
    line-height: 24px;
    font-weight: 400;
}

h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
}

.primary-text {
    color: #484848;
}

.secondary-text {
    color: #787878;
}

.padding-8 {
    padding: 8px;
}

.padding-12 {
    padding: 12px;
}

.padding-16 {
    padding: 16px;
}

.padding-20 {
    padding: 20px;
}

.valign-wrapper {
    display: table;
}

.valign {
    display: table-cell;
    vertical-align: middle;
}

.clearfix:after {
    content: "";
    clear: both;
    display: table;
}

table {
    border-collapse: collapse;
    border: none;
    width: 100%;
    height: auto;

    tr, th, td {
        padding: 0;
    }
}


button {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-shadow: 0;
    background: none;
    width: 100%;
    height: 60px;
    transition: all 0.3s;
}


.textfield {
    appearance: none;
    display: block;
    border: 0;
    border-bottom: .1rem solid #777777;
    outline: 0;
    position: relative;
    background-color: transparent;
    font-size: 16px;
    min-width: 100px;
    width: 100%;
    border-radius: 0;
    color: $text-color;

    &::-webkit-input-placeholder {
        color: #999999;
    }
}



.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(72, 72, 72, .35);
}


.btn-main {
    background-color: $primary-color;
    color: #ffffff;
    outline: 0;
    border: 0;

    &[disabled] {
        opacity: .3;
    }
}



.loading {
    position: relative;
    $spinner-height: 3.2rem;
    $spinner-color: #fff;

    &::after {
        opacity: 1;
        animation: loading 450ms infinite linear;
        border: .25rem solid $spinner-color;
        border-radius: 99999px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: "";
        display: block;
        height: $spinner-height;
        left: 50%;
        margin-left: -$spinner-height / 2;
        margin-top: -$spinner-height / 2;
        position: absolute;
        top: 50%;
        width: $spinner-height;
        z-index: 0;
    }
}

@keyframes loading {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.iti-flag {
    background-image: url("../images/flags.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2 / 1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    .iti-flag {
        background-image: url("../images/flags@2x.png");
    }
}
